import { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  SingleSectionElement,
  ConstructionSite,
  BuildingMaterialComponent,
  MountingAccessories,
  TypeOfRadiobox,
  BlindsAndShuttersRawCondition,
} from "features/addingMeasurement";
import { Button, Input, Textarea, Select } from "components";
import { VALIDATION_MESSAGES } from "enums";
import { usePutRollerBlindsInstallationInfo } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";

interface BlindsAndShuttersInstallationInformationType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const BlindsAndShuttersInstallationInformation = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: BlindsAndShuttersInstallationInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const initialMeasurementInstallationInfo =
    measurementInfo?.measurementInstallationInfo;

  const { mutate: editInstallationInfo } = usePutRollerBlindsInstallationInfo({
    onSuccess: () => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "3");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
    },
  });

  interface ObjectWithComment {
    comment: string;
  }

  const isObjectWithComment = (value: any): value is ObjectWithComment => {
    return (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value) &&
      typeof value.comment === "string" &&
      value.comment.trim() !== ""
    );
  };

  const validationSchema = Yup.object().shape({
    numberOfAssemblyTeams: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    plannedAssemblyTime: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    plannedInsulationThickness: Yup.string().required(
      VALIDATION_MESSAGES.REQUIRED
    ),
    typeOfInstallation: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        "validate-buildingWithFacade",
        'For "buildingWithFacade", one of installationInWindowRecesses or installationOnTheFacade must be true.',
        function (value) {
          const { installationInWindowRecesses, installationOnTheFacade } =
            this.parent;
          if (value === "buildingWithFacade") {
            return installationInWindowRecesses || installationOnTheFacade;
          }
          return true;
        }
      )
      .test(
        "validate-rawCondition",
        'For "rawCondition", at least one item in rawConditionUuids is required.',
        function (value) {
          const { rawConditionUuids } = this.parent;
          if (value === "rawCondition") {
            return (
              Array.isArray(rawConditionUuids) && rawConditionUuids.length > 0
            );
          }
          return true;
        }
      ),
    theBuildingIsMadeOf: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test("is-other-check", VALIDATION_MESSAGES.REQUIRED, function (value) {
        const { theBuildingIsMadeOfComment } = this.parent;
        if (value === "other" && !theBuildingIsMadeOfComment) {
          return false;
        }
        return true;
      }),
    theBuildingIsMadeOfComment: Yup.string(),
    constructionSiteUuids: Yup.array()
      .of(
        Yup.mixed().test(
          "is-string-or-object-with-comment",
          VALIDATION_MESSAGES.REQUIRED,
          (value) =>
            typeof value === "string" ||
            (typeof value === "object" &&
              value !== null &&
              !Array.isArray(value) &&
              typeof (value as ObjectWithComment).comment === "string" &&
              (value as ObjectWithComment).comment.trim() !== "")
        )
      )
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        "has-at-least-one-loose-string-or-object-with-comment",
        VALIDATION_MESSAGES.REQUIRED,
        (array) =>
          array.some(
            (item) => typeof item === "string" || isObjectWithComment(item)
          )
      ),
  });

  const handleOnSubmit = (values: any) => {
    if (values.typeOfInstallation === "buildingWithFacade") {
      delete values.rawCondition;
      const dynamicKey = values.typeOfInstallationElement;
      if (dynamicKey) {
        values[dynamicKey] = true;
      }
    }
    editInstallationInfo(values);
  };

  const constructionSiteComments =
    initialMeasurementInstallationInfo?.constructionSiteUuids.find(
      (item: any) => item.comment
    )?.comment;

  return (
    <div>
      <div className="am-heading">Informacje montażowe</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          theBuildingIsMadeOf:
            initialMeasurementInstallationInfo?.theBuildingIsMadeOf || [],
          constructionSiteUuids:
            initialMeasurementInstallationInfo?.constructionSiteUuids || [],
          plannedInsulationThickness:
            initialMeasurementInstallationInfo?.plannedInsulationThickness ||
            "",
          rawConditionUuids:
            initialMeasurementInstallationInfo?.rawConditionUuids || [],
          constructionSiteComments: constructionSiteComments || "",
          typeOfInstallation:
            initialMeasurementInstallationInfo?.typeOfInstallation || "",
          numberOfAssemblyTeams:
            initialMeasurementInstallationInfo?.numberOfAssemblyTeams || "",
          plannedAssemblyTime:
            initialMeasurementInstallationInfo?.plannedAssemblyTime || "",
          comments: initialMeasurementInstallationInfo?.comments || "",
          installationElementsUuids:
            initialMeasurementInstallationInfo?.installationElementsUuids || [],
          installationInWindowRecesses:
            initialMeasurementInstallationInfo?.installationInWindowRecesses ||
            false,
          installationOnTheFacade:
            initialMeasurementInstallationInfo?.installationOnTheFacade ||
            false,
          theBuildingIsMadeOfComment:
            initialMeasurementInstallationInfo?.theBuildingIsMadeOfComment ||
            "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values, actions) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          // console.log(values, "wartości!!");
          if (submitCount > 0) {
            setIsValidated(true);
          }

          return (
            <form
              className="amfs__form form amfs__form--blinds"
              onSubmit={handleSubmit}
            >
              <ScrollToError />
              <Field
                type={measurementInfo?.typeOfMeasurement}
                values={values}
                errors={errors}
                name="constructionSiteUuids"
                component={ConstructionSite}
              />
              <Field
                errors={errors}
                name="theBuildingIsMadeOf"
                component={BuildingMaterialComponent}
              />
              <SingleSectionElement
                touched={touched}
                name="plannedInsulationThickness"
                errors={errors}
              >
                <Field
                  label="Planowana grubość ocieplenia"
                  name="plannedInsulationThickness"
                  type="number"
                  rightPlaceholder="mm"
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                optional
                type={measurementInfo?.typeOfMeasurement}
                errors={errors}
                name="installationElementsUuids"
                component={MountingAccessories}
              />
              <SingleSectionElement
                touched={touched}
                name="typeOfInstallation"
                errors={errors}
                className="padding-top-separator static-alert-top single-section-element--installation-blinds"
              >
                <Field
                  label="Rodzaj montażu"
                  name="typeOfInstallation"
                  white
                  type="number"
                  rightPlaceholder="mm"
                  options={[
                    { value: "rawCondition", label: "Stan surowy" },
                    {
                      value: "buildingWithFacade",
                      label: "Budynek z elewacją",
                    },
                  ]}
                  component={Select}
                />
                {values.typeOfInstallation === "buildingWithFacade" && (
                  <Field
                    name={[
                      "installationInWindowRecesses",
                      "installationOnTheFacade",
                    ]}
                    options={[
                      {
                        label: "Montaż we wnękach okiennych",
                        value: "installationInWindowRecesses",
                        uuid: "installationInWindowRecesses",
                      },
                      {
                        label:
                          "Montaż na elewacji (dodać do wymiaru puszkę i prowadnicę)",
                        value: "installationOnTheFacade",
                        uuid: "installationOnTheFacade",
                      },
                    ]}
                    component={TypeOfRadiobox}
                  />
                )}
                {values.typeOfInstallation === "rawCondition" && (
                  <Field
                    name="rawConditionUuids"
                    component={BlindsAndShuttersRawCondition}
                  />
                )}
              </SingleSectionElement>
              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="plannedAssemblyTime"
                errors={errors}
              >
                <Field
                  label="Planowany czas montażu (w godzinach)"
                  name="plannedAssemblyTime"
                  maxLength={6}
                  type="number"
                  rightPlaceholder="h"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="numberOfAssemblyTeams"
                errors={errors}
              >
                <Field
                  label="Ilość osób potrzebnych do montażu"
                  name="numberOfAssemblyTeams"
                  maxLength={3}
                  type="number"
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BlindsAndShuttersInstallationInformation;
