import "./order-status.scss";
import classNames from "classnames";
import { ORDER_ENUM } from "enums";
import { hasOrderStatus } from "utils";

interface OrderStatusType {
  status?: string;
  className?: string;
  listOfActivity?: string;
}

const OrderStatus = ({
  status,
  className = "",
  listOfActivity,
}: OrderStatusType) => {
  const isMeasurementActivity = listOfActivity === "measurement";
  const isInstallationActivity = listOfActivity === "installation";
  const {
    MEASUREMENTS_TO_BE_RELEASED,
    FIXED_MEASUREMENTS,
    MEASUREMENTS_DONE,
    VERIFICATION_TO_BE_RELEASED,
    FIXED_VERIFICATION,
    INSTALLATION_TO_BE_RELEASED,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_DONE,
  } = ORDER_ENUM;

  const green =
    hasOrderStatus(status, [
      FIXED_MEASUREMENTS,
      FIXED_INSTALLATION,
      FIXED_VERIFICATION,
    ]) || isMeasurementActivity;
  const red =
    hasOrderStatus(status, [
      INSTALLATION_TO_BE_RELEASED,
      MEASUREMENTS_TO_BE_RELEASED,
      VERIFICATION_TO_BE_RELEASED,
    ]) || isInstallationActivity;
  const gray = hasOrderStatus(status, [INSTALLATION_DONE, MEASUREMENTS_DONE]);

  const orderStatusClass = classNames("order-status", className, {
    "order-status--green": green,
    "order-status--red": red,
    "order-status--gray": gray,
  });

  return (
    <div className={orderStatusClass}>
      <div className="order-status__label">
        <>
          {status === MEASUREMENTS_TO_BE_RELEASED && "Pomiar do ustalenia"}
          {status === FIXED_MEASUREMENTS && "Pomiar ustalony"}
          {status === MEASUREMENTS_DONE && "Pomiar zakończony"}
          {status === VERIFICATION_TO_BE_RELEASED && "Weryfikacja do ustalenia"}
          {status === FIXED_VERIFICATION && "Weryfikacja ustalona"}
          {status === INSTALLATION_TO_BE_RELEASED && "Montaż do ustalenia"}
          {status === FIXED_INSTALLATION && "Montaż ustalony"}
          {status === INSTALLATION_IN_PROGESS && "Montaż w trakcie"}
          {status === INSTALLATION_DONE && "Montaż zakończony"}
          {isInstallationActivity && "Montaż"}
          {isMeasurementActivity && "Pomiar"}
        </>
      </div>
    </div>
  );
};

export default OrderStatus;
