import { FILE_ENUM, ORDER_ENUM, USER_ROLE } from "enums";

export const isDeleteActive = (
  fileType: string | null,
  orderStatus: string,
  userRole: string | undefined,
): boolean => {
  const { MEASUREMENTS_PHOTOS, INSTALLATION_PHOTOS } = FILE_ENUM;
  const {
    FIXED_MEASUREMENTS,
    FIXED_INSTALLATION,
    INSTALLATION_TO_BE_RELEASED,
  } = ORDER_ENUM;
  const { fitter, measurer } = USER_ROLE
  const isFitter = userRole === fitter
  const isMeasurer = userRole === measurer


  return ( 
    (isFitter && orderStatus === (INSTALLATION_TO_BE_RELEASED || FIXED_INSTALLATION) && fileType === INSTALLATION_PHOTOS) || 
    ((isFitter || isMeasurer) && fileType === MEASUREMENTS_PHOTOS && orderStatus === FIXED_MEASUREMENTS)
  );
};