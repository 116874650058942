import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

export default function useGetChecklistOrder(groupUuid: string | null) {
  return useQuery({
    queryKey: ["checklistOrder", groupUuid],
    queryFn: async () => {
      return axiosInstance.get("/check-list/all", {
        params: { groupUuid },
      }).then((res) => res.data);
    },
    enabled: Boolean(groupUuid),
    select: (data) => data?.checkLists,
    retry: false,
  });
}
