import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM, ROUTE_ENUM } from 'enums';
import { queryClient } from 'App';
import { useNavigate } from 'react-router';

interface BackOrderType {
  orderUuid: string;
  comment: string;
  groupUuid?:string;
}

const useBackOrder = (
  options?: UseMutationOptions<any, Error, BackOrderType>
) => {
  const navigate = useNavigate();
  
  return useMutation({
    ...options,
    mutationKey: ['backOrder'],
    mutationFn: async ({ orderUuid, comment, groupUuid }) => {
      return axiosInstance.post(`/orders/status-back`, { orderUuid, comment, groupUuid }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Cofanie statusu pomiaru...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'back-measurement',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Status pomiaru został cofnięty pomyślnie',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'back-measurement',
      });
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      queryClient.invalidateQueries({ queryKey: ['measurements'] });
      navigate(ROUTE_ENUM.ORDERS_LIST);
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas cofania statusu pomiaru',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'back-measurement',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useBackOrder;
