import { NavLink } from "react-router-dom";
import { ROUTE_ENUM, SVG_TYPE, USER_ROLE } from "enums";
import { SVG } from "components";
import "./mobile-menu.scss";
import { UserType } from "types/UsersTypes";

interface MobileMenuType {
  user: UserType | undefined;
}

const MobileMenu = ({ user }: MobileMenuType) => {
  const isAdmin = user?.role === USER_ROLE.admin;
  const isScheduleManager = user?.role === USER_ROLE.scheduleManager;
  const {
    DASHBOARD_STATISTICS,
    ORDERS_LIST,
    USERS,
    EMPLOYEE_RATES,
    ASSEMBLY_MATERIAL,
    CALENDAR,
    EMPLOYEE_CALENDAR,
  } = ROUTE_ENUM;

  return (
    <div className="mobile-menu">
      {isAdmin ? (
        <>
          <NavLink to={DASHBOARD_STATISTICS} className="mobile-menu-item">
            <SVG type={SVG_TYPE.DASHBOARD} />
          </NavLink>
          <NavLink to={ORDERS_LIST} className="mobile-menu-item">
            <SVG type={SVG_TYPE.ORDERS_LIST} />
          </NavLink>
          <NavLink
            to={`${USERS}?page=1&location=1`}
            className="mobile-menu-item"
          >
            <SVG type={SVG_TYPE.USERS} />
          </NavLink>
          <NavLink
            to={`${EMPLOYEE_RATES}?page=1&tab=`}
            className="mobile-menu-item"
          >
            <SVG type={SVG_TYPE.EMPLOYEE_RATES} />
          </NavLink>
          <NavLink to={ASSEMBLY_MATERIAL} className="mobile-menu-item">
            <SVG type={SVG_TYPE.ASSEMBLY_MATERIALS} />
          </NavLink>
          <NavLink to={CALENDAR} className="mobile-menu-item">
            <SVG type={SVG_TYPE.CALENDAR} />
          </NavLink>
        </>
      ) : (
        <>
          <NavLink to={ORDERS_LIST} className="mobile-menu-item">
            <SVG type={SVG_TYPE.ORDERS_LIST} />
          </NavLink>
          <NavLink
            to={isScheduleManager || isAdmin ? EMPLOYEE_CALENDAR : CALENDAR}
            className="mobile-menu-item"
          >
            <SVG type={SVG_TYPE.CALENDAR} />
          </NavLink>
        </>
      )}
    </div>
  );
};

export default MobileMenu;
