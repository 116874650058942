import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { OrderType } from "types/OrderTypes";

export default function useGetOrderDetails(uuid: string | null, isAvailable: boolean = false, showPhotos?: boolean) {
  return useQuery({
    queryKey: ["orderDetails", uuid, { showPhotos }],
    queryFn: async () => {
      const response = await axiosInstance.get(`/orders/${uuid}`, {
        params: {
          showPhotos,
        },
      });
      return response.data;
    },
    select: (data: { order: OrderType }) => data?.order,
    retry: false,
    enabled: !!uuid && !!isAvailable,
  });
}
