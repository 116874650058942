import React from "react";
import { SkeletonRow } from "components";
import { useGetMe } from "hooks/auth";
import { ORDER_ENUM, USER_ROLE } from "enums";
// import ArchivalDatesDetails from "../ArchivalDatesDetails";
import { hasAccess, hasOrderStatus } from "utils";
import { meetingAddress } from "../utils";
import {
  ArchivalDatesDetails,
  DeterminedAndArrangedInstallation,
} from "features/orders";
import { OrderType } from "types/OrderTypes";

interface ActiveOrderDetailsProps {
  isLoading: boolean;
  orderDetails: OrderType;
  isProtocolApproved: boolean;
}

const ActiveOrderDetails = ({
  isLoading,
  orderDetails,
  isProtocolApproved,
}: ActiveOrderDetailsProps) => {
  const { data: authorizedUser } = useGetMe();
  const { role } = authorizedUser || { role: "" };
  const { status } = orderDetails;
  const { fitter, measurer, admin } = USER_ROLE;
  const {
    MEASUREMENTS_TO_BE_RELEASED,
    FIXED_MEASUREMENTS,
    MEASUREMENTS_DONE,
    VERIFICATION_TO_BE_RELEASED,
    FIXED_VERIFICATION,
  } = ORDER_ENUM;
  const labelComments = hasAccess(role, [fitter, measurer, admin]);
  const commentFromScheduler = hasOrderStatus(status, [
    MEASUREMENTS_TO_BE_RELEASED,
    FIXED_MEASUREMENTS,
    MEASUREMENTS_DONE,
    VERIFICATION_TO_BE_RELEASED,
    FIXED_VERIFICATION,
  ]);

  return (
    <>
      <div className="active-order__box active-order__box--measurement">
        <div className="active-order__heading">Szczegóły zlecenia</div>
        <div className="active-order__details"></div>
        <div className="active-order__details-heading">
          Imię i nazwisko klienta
        </div>
        <div className="active-order__details-paragraph">
          {!isLoading ? (
            orderDetails?.clientName
          ) : (
            <SkeletonRow count={1} width="200px" />
          )}
        </div>
        <div className="active-order__details-heading">Telefon</div>
        <div className="active-order__details-paragraph">
          {!isLoading ? (
            orderDetails?.phoneNumber
          ) : (
            <SkeletonRow count={1} width="200px" />
          )}
        </div>
        <div className="active-order__details-heading">Adres Email</div>
        <div className="active-order__details-paragraph">
          {!isLoading ? (
            orderDetails?.email
          ) : (
            <SkeletonRow count={1} width="200px" />
          )}
        </div>
        <div className="active-order__details-heading">Adres klienta</div>
        <div className="active-order__details-paragraph">
          {!isLoading ? (
            orderDetails?.address
          ) : (
            <SkeletonRow count={1} width="200px" />
          )}
        </div>
        {orderDetails?.date && (
          <>
            <div className="active-order__details-heading">
              {meetingAddress(orderDetails.status)}
            </div>
            <div className="active-order__details-paragraph">
              {!isLoading ? (
                `${orderDetails?.date}, godz. ${orderDetails?.start}-
              ${orderDetails?.end}`
              ) : (
                <SkeletonRow count={1} width="200px" />
              )}
            </div>
          </>
        )}
        <ArchivalDatesDetails userRole={role} orderDetails={orderDetails} />

        {commentFromScheduler && (
          <>
            <div className="active-order__details-heading">
              {labelComments ? "Uwagi od grafikowca" : "Uwagi dla pomiarowca"}
            </div>
            {!isLoading ? (
              <>
                {orderDetails?.comments ? (
                  <div className="active-order__details-paragraph">
                    {orderDetails?.comments}
                  </div>
                ) : (
                  "-"
                )}
              </>
            ) : (
              <SkeletonRow count={1} width="200px" />
            )}
          </>
        )}
        <DeterminedAndArrangedInstallation
          order={orderDetails}
          userRole={role}
          orderDetails={orderDetails}
        />
      </div>
    </>
  );
};

export default ActiveOrderDetails;
