import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { queryClient } from 'App';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { useSearchParams } from "react-router-dom";

interface UploadFileData {
  parentUuid: string;
  type: string;
  files: File[];
}

const useUploadFile = (
  options?: UseMutationOptions<any, Error, UploadFileData>
) => {
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  return useMutation({
    ...options,
    mutationKey: ['uploadFile'],
    mutationFn: async ({ files, parentUuid, type }: UploadFileData) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });

      const params = new URLSearchParams({
        parentUuid: parentUuid,
        type: type,
      });
      const responsePromise = axiosInstance.post(`/files?${params.toString()}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => res.data);
      const delayPromise = new Promise((resolve) => setTimeout(resolve, 500));
      const [response] = await Promise.all([responsePromise, delayPromise]);

      return response;
    },
    onMutate: (variables) => {
      Toast({
        message: 'Trwa przetwarzanie pliku.',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'send-file',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['orderDetails', orderUuid] });
      Toast({
        message: 'Plik został zapisany',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'send-file',
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Plik nie został zapisany',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'send-file',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useUploadFile;
