import { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import {
  InstallationPlanning,
  Button,
  StaticAlert,
  InstallationArranging,
  SVG,
  Textarea,
} from "components";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { measurementAllInstallationType } from "types/OrderTypes";
import { SVG_TYPE, VALIDATION_MESSAGES } from "enums";
import {
  useAddInstallationOrder,
  useUpdateInstallationOrder,
} from "hooks/orders";
import { useNavigate } from "react-router";
import "./installations-form.scss";

interface installationsFormType {
  orderDetails: any;
  isEdit: boolean;
}

const InstallationsForm = ({ orderDetails, isEdit }: installationsFormType) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showError, setShowError] = useState(false);

  const [step, setStep] = useState(1);
  const uuid = searchParams.get("uuid");
  const [initialValues, setInitialValues] = useState<any>({
    installations: [],
    uuid,
    comment: "",
  });

  const handleOnSubmit = (values: any) => {
    const newDates = {
      date: "",
      team: [{ employeeUuids: [], start: "", end: "" }],
    };

    if (
      step === 1 &&
      values.installations.length > 0 &&
      values.installations[0].measurementNames.length > 0
    ) {
      const updatedInstallations = values.installations.map(
        (installation: any) => {
          return {
            ...installation,
            dates:
              installation.dates && installation.dates.length > 0
                ? installation.dates
                : [newDates],
          };
        }
      );

      setInitialValues({ ...values, installations: updatedInstallations });
      setStep(2);
      setShowError(false);
    } else if (step === 1) {
      setShowError(true);
    }

    if (step === 2) {
      isEdit
        ? updatedMutation.mutate({ uuid, ...values })
        : mutation.mutate({ uuid, ...values });
    }
  };

  const mutation = useAddInstallationOrder({
    onSuccess: () => {
      navigate(-1);
    },
  });

  const updatedMutation = useUpdateInstallationOrder({
    onSuccess: () => {
      navigate(-1);
    },
  });

  useEffect(() => {
    if (
      isEdit &&
      orderDetails &&
      orderDetails.measurementArrangedInstallation
    ) {
      const transformedData = orderDetails.measurementArrangedInstallation.map(
        (item: any) => ({
          ...item,
          dates: item.dates.map((dateItem: any) => ({
            ...dateItem,
            team: dateItem.team.map((teamItem: any) => ({
              ...teamItem,
              employeeUuids: teamItem.employeeUuids.map(
                (employee: any) => employee.uuid
              ),
            })),
          })),
        })
      );
      setInitialValues({
        uuid,
        installations: transformedData,
      });
    }
  }, [orderDetails, isEdit, uuid]);

  const InstallationPlanningSchema = Yup.object().shape({
    installations: Yup.array().of(
      Yup.object().shape({
        dates: Yup.array().of(
          Yup.object().shape({
            date: Yup.string()
              .required(VALIDATION_MESSAGES.REQUIRED)
              .nullable(),
            team: Yup.array().of(
              Yup.object().shape({
                start: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
                end: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
                employeeUuids: Yup.array()
                  .of(Yup.string().required())
                  .required(VALIDATION_MESSAGES.REQUIRED)
                  .min(1, VALIDATION_MESSAGES.REQUIRED),
              })
            ),
          })
        ),
      })
    ),
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  const ordersDetailsMeasurements = isEdit
    ? orderDetails?.measurementsArrangedInstallations
    : orderDetails?.measurementsToBeDetermined;
  const arrangedInstallation = orderDetails.measurementsArrangedInstallations;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={step === 2 && InstallationPlanningSchema}
        onSubmit={async (values) => handleOnSubmit(values)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, errors, values, validateField }) => {
          return (
            <form className="installation-planning__form">
              <div className="installation-planning__steps__content">
                <div className="installation-planning__steps">
                  {step === 2 && (
                    <span onClick={() => setStep(1)}>
                      <SVG type={SVG_TYPE.ARROW_BACK} />
                    </span>
                  )}
                  Krok {step}
                  <span>/2</span>
                </div>
                <div className="installation-planning__steps__paragraph">
                  {step === 1
                    ? "Wybierz typy montaży, które chcesz umówić."
                    : "Przypisz pracowników i terminy."}
                </div>
              </div>
              <div className="modal__information-header">
                Montaż do ustalenia
              </div>
              <div className="installation-planning__wrapper">
                {ordersDetailsMeasurements.map(
                  (details: measurementAllInstallationType, index: number) => {
                    return (
                      <>
                        <InstallationPlanning
                          setShowError={setShowError}
                          step={step}
                          installationIndex={index}
                          key={`${details.plannedAssemblyTime}--${index}`}
                          details={details}
                          errors={errors}
                          initialValues={values}
                          setInitialValues={setInitialValues}
                          isEdit={isEdit}
                          validateField={validateField}
                        />
                      </>
                    );
                  }
                )}
                {step === 2 && (
                  <Field
                    className="installation-planning--comment"
                    errors={errors}
                    label="Dodatkowe uwagi"
                    name="installations.[0].comment"
                    optional
                    component={Textarea}
                    maxLength={250}
                  />
                )}
              </div>
              <StaticAlert
                show={showError}
                label="Wybierz montaż do ustalenia, aby przejść do następnego kroku."
              />
              {step === 1 && arrangedInstallation.length > 0 && (
                <>
                  <div className="installation-planning__wrapper installation-arranging__wrapper">
                    <div className="modal__information-header">
                      Ustalone montaże
                    </div>
                    {arrangedInstallation.map((installation: any) => {
                      return (
                        <InstallationArranging installation={installation} />
                      );
                    })}
                  </div>
                </>
              )}

              <div className="modal-buttons aap__buttons">
                <Button stroke onClick={handleGoBack} label="Anuluj" />
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  label={step === 1 ? "Następny krok" : "Zapisz"}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default InstallationsForm;
