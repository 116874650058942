import { SVG } from "components";
import classnames from "classnames";
import "./button.scss";

interface ButtonProps {
  className?: string;
  label: string;
  onClick?: (e: any) => void;
  secondary?: boolean;
  stroke?: boolean;
  warning?: boolean;
  type?: "button" | "submit" | "reset";
  size?: "large" | "small" | "medium";
  svgType?: string;
  disabled?: boolean;
}

const Button = ({
  className,
  label,
  onClick,
  secondary = false,
  warning = false,
  stroke = false,
  type = "button",
  size = "large",
  disabled = false,
  svgType,
}: ButtonProps) => {
  const buttonClass = classnames("btn", className, {
    "btn-secondary": secondary && !stroke,
    "btn-primary": !secondary && !stroke,
    "btn-stroke": stroke,
    "btn-stroke--primary": stroke && !secondary,
    "btn-stroke--secondary": stroke && secondary,
    "btn-stroke--warning": stroke && warning,
    "btn--disabled": disabled,
    "btn--large": size === "large",
    "btn--medium": size === "medium",
    "btn--small": size === "small",
  });

  return (
    <button
      onClick={(e) => onClick && onClick(e)}
      type={type}
      disabled={disabled}
      className={buttonClass}
    >
      {label}
      {svgType && <SVG type={svgType} />}
    </button>
  );
};

export default Button;
