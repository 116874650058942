import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type FinishInstallationData = {
  groupUuid: string;
};

const useFinishInstallation = (
  options?: UseMutationOptions<any, Error, FinishInstallationData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['finishInstallation'],
    mutationFn: async ({ groupUuid }: FinishInstallationData) => {
      return axiosInstance
        .put(`/installation/finish/${groupUuid}`)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Zakończenie montażu w toku...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'finish-installation',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Montaż zakończony pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'finish-installation',
      });
      // Invalidate related queries
      queryClient.invalidateQueries({ queryKey: ['installationGroups'] });
      queryClient.invalidateQueries({ queryKey: ['orderStatus'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas zakończania montażu.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'finish-installation',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useFinishInstallation;
