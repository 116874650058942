import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetOrderDetails, useFinishInstallation } from "hooks/orders";
import { useGetMeasurements } from "hooks/measurements";
import { SVG_TYPE, ROUTE_ENUM, ORDER_ENUM, FILE_ENUM } from "enums";
import { Button, SVG } from "components";
import { useNavigate } from "react-router";
import {
  CloseMeasurement,
  ActiveOrderDetails,
  Measurements,
  Verifications,
  AppointmentCannotHappen,
  ReceiptProtocolGenerator,
  InvoiceAndPaymentMethods,
} from "features/orders";
import { UsedAssemblyMaterials } from "features/usedAssemblyMaterials";
import { Photos } from "features/Photos";
import "./active-order.scss";
import { ActivityTime } from "features/activity";
import { useGetMe } from "hooks/auth";

const ActiveOrder = () => {
  const navigate = useNavigate();
  const { data: authorizedUser } = useGetMe();
  const userRole = authorizedUser && authorizedUser?.role;
  const [isCloseMeasurementOpen, setIsCloseMeasurementOpen] = useState(false);
  const [isErrorPhotos, setIsErrorPhotos] = useState(false);
  const [errorEndAppointment, setErrorEndAppointment] = useState({
    isError: false,
    errorMessage: "",
  });
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid") || "";
  const groupUuid = searchParams.get("groupUuid");
  const page = searchParams.get("page");
  const orderDetailsUrl = `?page=${page}&orderUuid=${orderUuid}`;
  const { data: orderDetails } = useGetOrderDetails(orderUuid, true, true);
  const { data: measurements, isLoading } = useGetMeasurements(orderUuid);
  const { mutate: finishInstallation } = useFinishInstallation({
    onSuccess: () => {
      handleGoToOrdersList();
    },
  });

  const {
    FIXED_VERIFICATION,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_TO_BE_RELEASED,
    FIXED_MEASUREMENTS,
  } = ORDER_ENUM;
  const verifications = orderDetails?.status === FIXED_VERIFICATION;
  const { INSTALLATION_PHOTOS, MEASUREMENTS_PHOTOS } = FILE_ENUM;
  const groupInstallation =
    orderDetails &&
    orderDetails.measurementsArrangedInstallations?.find(
      (installationGroup) => installationGroup.groupUuid === groupUuid
    );
  const isProtocolApproved = !!groupInstallation?.protocolApproved || false;

  const fixedInstallation =
    orderDetails?.status === INSTALLATION_TO_BE_RELEASED ||
    orderDetails?.status === FIXED_INSTALLATION ||
    orderDetails?.status === INSTALLATION_IN_PROGESS;
  const fixedMeasurement = orderDetails?.status === FIXED_MEASUREMENTS;
  const handleGoToOrdersList = () => {
    navigate(`${ROUTE_ENUM.ORDERS_LIST}${orderDetailsUrl}`);
  };

  const handleCloseOrder = () => {
    const isAnyMeasurementIncomplete =
      measurements &&
      measurements.length > 0 &&
      measurements.some((item) => !item.isFinished);
    const isAnyMeasurementAvailable = measurements && measurements.length > 0;
    if (
      orderDetails &&
      orderDetails.photos &&
      orderDetails.photos.length === 0
    ) {
      setIsErrorPhotos(true);
    }
    if (!isAnyMeasurementAvailable && !isAnyMeasurementIncomplete) {
      setErrorEndAppointment({
        isError: true,
        errorMessage:
          "Aby zakończyć zlecenie musisz dodać przynajmniej jeden pomiar.",
      });
    } else if (isAnyMeasurementAvailable && isAnyMeasurementIncomplete) {
      setErrorEndAppointment({
        isError: true,
        errorMessage:
          "Masz niedokończone pomiary. Uzupełnij brakujące dane, aby móc zakończyć zlecenie.",
      });
    } else if (
      isAnyMeasurementAvailable &&
      !isAnyMeasurementIncomplete &&
      orderDetails &&
      orderDetails.photos &&
      orderDetails.photos.length > 0
    ) {
      setErrorEndAppointment({ isError: false, errorMessage: "" });
      setIsCloseMeasurementOpen(true);
    }
  };

  const handleCloseInstallation = () => {
    const noPhoto =
      orderDetails && orderDetails.photos && orderDetails.photos.length === 0;
    const hasInstallationPhoto =
      orderDetails &&
      orderDetails.photos.filter(
        (photos) => photos.type === INSTALLATION_PHOTOS
      ).length === 0;

    if (noPhoto || hasInstallationPhoto) {
      setIsErrorPhotos(true);
    } else {
      setIsErrorPhotos(false);
      groupUuid && finishInstallation({ groupUuid });
    }
  };

  return (
    <>
      {orderDetails && (
        <>
          <CloseMeasurement
            data={{ label: orderDetails?.name, uuid: orderUuid }}
            isOpen={isCloseMeasurementOpen}
            onClose={() => setIsCloseMeasurementOpen(false)}
          />
          <div className="dashboard-tab-name dashboard-tab-name-with-back">
            <div
              className="dashboard-tab-name-with-back__icon"
              onClick={() => handleGoToOrdersList()}
            >
              <SVG type={SVG_TYPE.ARROW_BACK} />
            </div>
            {orderDetails?.name}
          </div>
          <div className="active-order__content">
            <div className="active-order__box active-order__box--left">
              <ActivityTime />
              {fixedInstallation && (
                <>
                  <UsedAssemblyMaterials orderDetails={orderDetails} />
                  <InvoiceAndPaymentMethods orderDetails={orderDetails} />
                  <Photos
                    userRole={userRole}
                    setIsErrorPhotos={setIsErrorPhotos}
                    isErrorPhotos={isErrorPhotos}
                    orderDetails={orderDetails}
                    type={INSTALLATION_PHOTOS}
                    label="Zdjęcia"
                  />
                  <AppointmentCannotHappen
                    groupUuid={groupUuid}
                    uuid={orderUuid}
                    type="installation"
                    handleCloseOrder={handleCloseInstallation}
                  />
                </>
              )}
              {fixedMeasurement && (
                <>
                  <Measurements
                    isLoading={isLoading}
                    errorEndMeasurement={errorEndAppointment}
                    measurements={measurements}
                  />
                  <Photos
                    userRole={userRole}
                    setIsErrorPhotos={setIsErrorPhotos}
                    isErrorPhotos={isErrorPhotos}
                    orderDetails={orderDetails}
                    type={MEASUREMENTS_PHOTOS}
                    label="Zdjęcia"
                  />
                  <AppointmentCannotHappen
                    uuid={orderUuid}
                    type="measurement"
                    handleCloseOrder={handleCloseOrder}
                  />
                </>
              )}
              {verifications && (
                <Verifications
                  uuid={orderUuid}
                  verifications={orderDetails?.verificationParams}
                />
              )}
            </div>
            <div className="active-order--right">
              <ActiveOrderDetails
                isProtocolApproved={isProtocolApproved}
                isLoading={isLoading}
                orderDetails={orderDetails}
              />
              {fixedInstallation && (
                <ReceiptProtocolGenerator orderDetails={orderDetails} />
              )}
              <Button
                label="Zakończ zlecenie"
                className="active-order__details-button"
                onClick={() => handleCloseOrder()}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ActiveOrder;
