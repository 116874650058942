/* eslint-disable react-hooks/exhaustive-deps */
import {
  SVG_TYPE,
  ROUTE_ENUM,
  USER_ROLE,
  ARCHIVAL_TYPE,
  ORDER_ENUM,
} from "enums";
import { SVG, Button } from "components";
import { Tooltip } from "react-tooltip";
import "./calendar-page-preview.scss";
import moment from "moment";
import "moment/locale/pl";
import { useNavigate } from "react-router";
import { UserType } from "types/UsersTypes";
import { OrderPreview } from "features/orders";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { hasOrderStatus } from "utils";

interface CalendarPagePreviewType {
  event: any;
  user?: UserType;
  date: any;
  setActiveEvent?: any;
  activeEvent?: any;
}
const CalendarPagePreview = ({
  event,
  date,
  user,
  setActiveEvent,
  activeEvent,
}: CalendarPagePreviewType) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { measurer, admin } = USER_ROLE;
  const { ARRANGING_MEASUREMENT, ACTIVE_ORDER } = ROUTE_ENUM;
  const { FIXED_MEASUREMENTS, FIXED_VERIFICATION } = ORDER_ENUM;
  const uuid = searchParams.get("uuid");
  const isMeasurer = user?.role === measurer;
  const isAdmin = user?.role === admin;
  const isOpen = activeEvent === event.original && isAdmin;

  useEffect(() => {
    if (uuid && !activeEvent) {
      if (uuid === event.original.uuid) {
        setActiveEvent(event.original);
      }
    }
  }, []);

  const handleClickEdit = () => {
    navigate(
      `${ARRANGING_MEASUREMENT}?uuid=${event.original.uuid}&tab=${event.original.status}&edit=true`
    );
  };

  const handleClose = () => {
    const dashboard = document.getElementsByClassName("dashboard");
    if (dashboard.length > 0) {
      const dashboardElement = dashboard[0] as HTMLElement;
      dashboardElement.click();
    }
  };

  const handleAfterHide = () => {
    const elements = document.querySelectorAll(".cpp-day__tooltip-open");
    elements.forEach((element) => {
      element.classList.remove("cpp-day__tooltip-open");
    });
  };

  moment.locale("pl");
  const formattedDate = moment(event.original.date, "DD/MM/YYYY").format(
    "dddd, D MMMM"
  );
  const isMonday = moment(event.original.date, "DD/MM/YYYY").day() === 1;

  const tooltipPlace = isMonday ? "right" : "bottom";

  const handleClickOrder = () => {
    navigate(`${ACTIVE_ORDER}?orderUuid=${event.original.uuid}`);
  };

  const handleOnClose = () => {
    setActiveEvent(null);
    const params = new URLSearchParams(window.location.search);
    if (params.has("uuid")) {
      params.delete("uuid");
      navigate({ search: params.toString() }, { replace: true }); // Replace current history entry
    }
  };

  const isEditActive =
    !isMeasurer &&
    (event.original.type === ARCHIVAL_TYPE.INSTALLATION_PLANNED ||
      hasOrderStatus(event.original.status, [
        FIXED_VERIFICATION,
        FIXED_MEASUREMENTS,
      ]));

  console.log(event.original);

  return (
    <>
      {isAdmin && event ? (
        <OrderPreview
          userRole={user?.role}
          isOpen={isOpen}
          order={event.original}
          onClose={() => handleOnClose()}
          calendarPagePreview={true}
        />
      ) : (
        <Tooltip
          afterHide={handleAfterHide}
          className="cpp"
          anchorSelect={`#cpp-afera--${event.index}`}
          clickable
          openOnClick
          place={tooltipPlace}
        >
          <div className="cpp__wrapper" onClick={(e) => e.stopPropagation()}>
            <div className="cpp__content" onClick={(e) => e.stopPropagation()}>
              <div className="cpp__top" onClick={(e) => e.stopPropagation()}>
                <div className="cpp__actions">
                  {isEditActive && (
                    <div
                      className="cpp__action"
                      onClick={() => handleClickEdit()}
                    >
                      <SVG type={SVG_TYPE.EDIT_PENCIL} />
                    </div>
                  )}
                  <div className="cpp__action" onClick={() => handleClose()}>
                    <SVG type={SVG_TYPE.CLOSE_CIRCLE} />
                  </div>
                </div>
              </div>
              <div className="cpp__event">
                <div className="cpp__event-name">{event.original.title}</div>
                <div className="cpp__event-date">
                  {formattedDate} {event.original.start}-{event.original.end}
                </div>
                <div className="cpp__event-address">
                  {event.original.address}
                </div>
                {!isMeasurer && (
                  <div className="cpp__event-user">
                    <div
                      style={{ backgroundColor: event.original.userColor }}
                      className="cpp__event-user-box"
                    ></div>
                    <div className="cpp__event-user-name">
                      {event.original.users &&
                        event.original.users.length > 0 &&
                        event.original.users.map((item: any, index: number) => {
                          const isMoreThanOneAndNotLast =
                            index + 1 < event.original.users.length;
                          return (
                            <>
                              {item.name}
                              {isMoreThanOneAndNotLast ? ", " : ""}
                            </>
                          );
                        })}
                    </div>
                  </div>
                )}
                {isMeasurer && (
                  <div className="cpp__buttons">
                    <Button
                      label="Wykonaj"
                      onClick={() => handleClickOrder()}
                      size="small"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default CalendarPagePreview;
