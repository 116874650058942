import { Formik, Field } from "formik";
import { Input, Switch, Button, FourState } from "components";
import "./invoice-and-payment-methods.scss";
import { SVG_TYPE, VALIDATION_MESSAGES } from "enums";
import { usePutInvoiceInfo } from "hooks/orders";
import { OrderType, PaymentMethodEnum } from "types/OrderTypes";
import * as Yup from "yup";

interface InvoiceAndPaymentMethodsProps {
  orderDetails: OrderType;
}
const InvoiceAndPaymentMethods = ({
  orderDetails,
}: InvoiceAndPaymentMethodsProps) => {
  const { fileInvoice, uuid, invoiceByMail, mailAddress, paymentMethod } =
    orderDetails;
  const { name, url } = fileInvoice || { name: "", url: "" };
  const handleDownloadFileItem = () => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const { mutate: updateInvoiceInfo } = usePutInvoiceInfo();

  const handleUpdateInvoiceMail = (isInvoiceMail: boolean) => {
    updateInvoiceInfo({
      uuid: uuid,
      invoiceByMail: isInvoiceMail,
    });
  };
  const handleUpdateMailAddress = (mailAddress: string) => {
    updateInvoiceInfo({
      uuid: uuid,
      mailAddress,
    });
  };
  const handleChangePaymentMethod = (paymentMethod: PaymentMethodEnum) => {
    updateInvoiceInfo({
      uuid: uuid,
      paymentMethod,
    });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .email(VALIDATION_MESSAGES.EMAIL),
  });

  return (
    <div className="invoice-and-payment-methods">
      <Formik
        initialValues={{
          invoiceMail: !!invoiceByMail || false,
          email: mailAddress || "",
          paymentMethod: paymentMethod || "noPayment",
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={() => console.log()}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, errors, validateField }) => {
          return (
            <form
              className="invoice-and-payment-methods__form"
              onSubmit={(e) => e.preventDefault()}
            >
              <Field
                checked={values.invoiceMail}
                label="Faktura na maila"
                secondary
                name="invoiceMail"
                component={Switch}
                onChange={(e: any) => handleUpdateInvoiceMail(e.target.checked)}
              />
              {values.invoiceMail && (
                <div
                  className={
                    !url ? "invoice-and-payment-methods__form__button" : ""
                  }
                >
                  <Field
                    label="Adres email do wysyłki "
                    secondary
                    name="email"
                    component={Input}
                    errors={errors}
                    onBlur={async (e: any) => {
                      const email = e.target.value;
                      await validateField("email");
                      if (!errors.email) {
                        handleUpdateMailAddress(email);
                      }
                    }}
                  />
                </div>
              )}
              {url && (
                <div className="invoice-and-payment-methods__form__button">
                  <Button
                    label="Pobierz Fakturę"
                    svgType={SVG_TYPE.DOWNLOAD_FILE}
                    stroke
                    onClick={() => handleDownloadFileItem()}
                    size="medium"
                  />
                </div>
              )}
              <FourState
                secondary
                label="Metoda płatności"
                values={values}
                setFieldValue={setFieldValue}
                name="paymentMethod"
                first={{ name: "Gotówka", value: "cash" }}
                second={{ name: "Przelew", value: "transfer" }}
                third={{ name: "Salon", value: "inShowroom" }}
                fourth={{ name: "Brak", value: "noPayment" }}
                onChange={(e) => handleChangePaymentMethod(e.target.value)}
              />
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InvoiceAndPaymentMethods;
