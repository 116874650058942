import { Button, Modal } from "components";
import { useDeleteItemLeftAtClient } from "hooks/orders";

interface RemoveItemLeftAtClientProps {
  modalManage: { which: string; element: string; uuid: string };
  onClose: () => void;
}

const RemoveItemLeftAtClient = ({
  onClose,
  modalManage: { which, element, uuid },
}: RemoveItemLeftAtClientProps) => {
  const isOpen = which === "delete";
  const { mutate: removeItem } = useDeleteItemLeftAtClient({
    onSuccess: onClose,
  });

  const handleDeleteItem = async () => {
    await removeItem({ uuid });
  };

  return (
    <Modal isOpened={isOpen} onClose={() => onClose()}>
      <>
        <div className="modal-heading">
          Usuwanie rzeczy pozostawionej u klienta
        </div>
        <div className="modal-description">
          Czy jesteś pewien, że chcesz usunąć <span> {element}?</span>
        </div>
        <div className="modal-buttons">
          <Button stroke onClick={() => onClose()} label="Anuluj" />
          <Button onClick={handleDeleteItem} label="Potwierdź" />
        </div>
      </>
    </Modal>
  );
};

export default RemoveItemLeftAtClient;
