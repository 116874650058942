/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { SelectOption } from "types/SelectTypes";
import "./select.scss";
import Select, { components, ActionMeta } from "react-select";
import { SVG } from "components";
import { SVG_TYPE } from "enums";
import classNames from "classnames";
import { isMobile } from "utils";

interface SelectProps {
  label?: string;
  options?: SelectOption[];
  placeholder: string;
  name: string;
  filterOption?: any;
  checker?: boolean;
  white?: boolean;
  maxLength?: number;
  optional?: boolean;
  disableMobileKeyboard?: boolean;
  menuPlacement?: "top" | "bottom";

  field?: any;
  errors?: any;
  form?: any;
  touched?: any;
  onChange?: (data: any) => void;
  handleOnChange?: any;
  isOnBlurAction?: boolean;
  editUser?: () => void;
  onFocus?: any;
  disabled?: boolean;
  size?: "large" | "small" | "medium";
  className?: string;
}

const CustomDropdownIndicator = (props: any) => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      {selectProps.menuIsOpen ? (
        <SVG type={SVG_TYPE.CHEVRON_TOP} />
      ) : (
        <SVG type={SVG_TYPE.CHEVRON_DOWN} />
      )}
    </components.DropdownIndicator>
  );
};

const SelectComponent = ({
  label,
  options,
  name,
  filterOption,
  placeholder,
  field,
  form,
  errors,
  onChange,
  disabled = false,
  checker = false,
  optional = false,
  disableMobileKeyboard = false,
  white = false,
  size = "large",
  menuPlacement = "bottom",
  className = "",
  maxLength,
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null
  );
  const [inputValue, setInputValue] = useState("");
  const [textPart, setTextPart] = useState("");

  const selectClass = classNames("select", className, {
    "select--white": white,
    "select--disabled": disabled,
    "select--large": size === "large",
    "select--medium": size === "medium",
    "select--small": size === "small",
    "select--optional": optional,
  });

  const selectPrefixChecker = classNames({
    "select-checker": checker,
    "select-checker--large": checker && size === "large",
    "select-checker--medium": checker && size === "medium",
    "select-checker--small": checker && size === "small",
    select: !checker,
  });

  const selectPrefixClass = classNames("multiple-select", {
    "multiple-select--large": size === "large",
    "multiple-select--medium": size === "medium",
    "multiple-select--small": size === "small",
  });

  const isArrayField = field.name.includes(".");
  const dotIndex = field.name.indexOf(".");
  function removeNonNumericChars(inputString: any) {
    return inputString.replace(/\D/g, "");
  }
  const elementIndex = field.name.substring(dotIndex + 1);
  const fieldName = field.name.substring(0, dotIndex);

  useEffect(() => {
    if (isArrayField) {
      const parts = field.name.split(".");
      if (parts.length > 2) {
        const dynamicTextPart = parts.slice(2).join(".");
        setTextPart(dynamicTextPart);
      }
    }
  }, [form.values[fieldName], field.value]);

  function showError() {
    if (isArrayField) {
      if (dotIndex !== -1) {
        if (elementIndex.includes(".")) {
          const afterRemoveString = removeNonNumericChars(elementIndex);

          const errorTextPart =
            errors[fieldName]?.[afterRemoveString]?.[textPart] || "";
          return (
            <div className="input__error">
              {form?.touched[fieldName] &&
                form.touched[fieldName][afterRemoveString] && (
                  <span>{errorTextPart}</span>
                )}
            </div>
          );
        } else {
          const errorTextPart =
            errors[fieldName]?.[elementIndex]?.[textPart] || "";

          return (
            <div className="select__error">
              {form?.touched[fieldName] &&
                form.touched[fieldName][elementIndex] && (
                  <span>{errorTextPart}</span>
                )}
            </div>
          );
        }
      } else {
        return null;
      }
    } else if (errors && form.touched) {
      const errorTextPart =
        errors[field.name]?.[textPart] || errors[field.name] || "";
      return (
        <div className="select__error">
          {form.touched[field.name] && <span>{errorTextPart}</span>}
        </div>
      );
    }
  }

  useEffect(() => {
    if (field.value) {
      const initialValue = options?.find(
        (option) => option.value === field.value
      );
      setSelectedOption(initialValue || null);
    } else {
      setSelectedOption(null);
    }
  }, [field.value, options]);

  const handleChange = (
    newValue: SelectOption | null,
    actionMeta?: ActionMeta<SelectOption>
  ) => {
    setSelectedOption(newValue);
    const valueToStore = newValue ? newValue.value : "";
    form.setFieldValue(field.name, valueToStore);
    onChange && onChange(newValue);
  };

  const NoOptionsMessage = () => {
    return <div>Nie znaleziono</div>;
  };

  const handleInputChange = (newValue: string) => {
    if (maxLength && newValue.length > maxLength) {
      setInputValue(newValue.slice(0, maxLength));
    } else {
      setInputValue(newValue);
    }
  };

  const filterOptions = (candidate: SelectOption, input: string) => {
    if (filterOption) {
      return filterOption(candidate, input);
    }
    return candidate.label.toLowerCase().includes(input.toLowerCase());
  };

  const limitedOptions = inputValue
    ? options
        ?.filter((option) => filterOptions(option, inputValue))
        .slice(0, maxLength)
    : options;

  return (
    <div className="select__wrapper">
      <div className="select__label">
        {label}{" "}
        {optional ? <span className="select__optional">(opcjonalne)</span> : ""}
      </div>
      <Select
        value={selectedOption}
        name={name}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        isClearable={optional}
        onChange={handleChange}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        placeholder={placeholder ? placeholder : ""}
        options={limitedOptions}
        className={selectClass}
        classNamePrefix={checker ? selectPrefixChecker : selectPrefixClass}
        noOptionsMessage={() => <NoOptionsMessage />}
        filterOption={filterOption}
        isSearchable={disableMobileKeyboard && isMobile() ? false : true}
        menuPlacement={menuPlacement}
      />
      {showError()}
    </div>
  );
};

export default SelectComponent;
