import { Button, SVG } from "components";
import { USER_ROLE, ORDER_ENUM, SVG_TYPE, ROUTE_ENUM } from "enums";
import { OrderListType, OrderType } from "types/OrderTypes";
import { MEASUREMENTS_TYPE_TRANSLATIONS } from "enums";
import { hasAccess, hasOrderStatus } from "utils";
import { useNavigate } from "react-router";
import "./determined-and-arranged-installation.scss";
import { useSearchParams } from "react-router-dom";

interface DeterminedAndArrangedInstallationProps {
  orderDetails: OrderType;
  handleClickAppointment?: (type: string) => void;
  userRole?: string;
  order: OrderListType;
}

const DeterminedAndArrangedInstallation = ({
  orderDetails,
  handleClickAppointment,
  userRole,
  order,
}: DeterminedAndArrangedInstallationProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const groupUuidUrl = searchParams.get("groupUuid");
  const { ACTIVE_ORDER } = ROUTE_ENUM;
  const { admin, fitter, scheduleManager } = USER_ROLE;
  const {
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_TO_BE_RELEASED,
  } = ORDER_ENUM;
  const {
    measurementsArrangedInstallations,
    measurementsToBeDetermined,
    status,
  } = orderDetails;
  const orderStatusAccess = hasOrderStatus(status, [
    INSTALLATION_TO_BE_RELEASED,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
  ]);
  const roleAccess = hasAccess(userRole, [admin, fitter, scheduleManager]);
  const isFitter = hasAccess(userRole, [fitter]);
  const isScheduler = hasAccess(userRole, [scheduleManager]);
  const editAccess =
    hasAccess(userRole, [scheduleManager]) && handleClickAppointment;
  const showSection = orderStatusAccess && roleAccess;

  const handleClickInstallation = () => {
    navigate(
      `${ACTIVE_ORDER}?page=${page}&orderUuid=${order.uuid}&tab=${status}${
        order.groupUuid ? `&groupUuid=${order.groupUuid}` : ""
      }`
    );
  };

  const isActiveHandleClickInstallation = !groupUuidUrl && isFitter;

  return (
    <>
      {showSection && (
        <>
          {!isFitter && (
            <>
              {measurementsToBeDetermined &&
                measurementsToBeDetermined.length > 0 && (
                  <div className="determined-and-arranged-installation">
                    {isScheduler && <div className="am-separator" />}
                    <div className="modal__information-header determined-and-arranged-installation__header">
                      Montaże do ustalenia
                      {editAccess && (
                        <Button
                          size="small"
                          label="Umów"
                          onClick={() => handleClickAppointment("")}
                        />
                      )}
                    </div>
                    <div className="installation-planning">
                      {measurementsToBeDetermined.map(
                        (
                          {
                            plannedAssemblyTime,
                            numberOfAssemblyTeams,
                            type,
                            counter,
                          },
                          index
                        ) => {
                          const moreThanOne = index > 0;
                          return (
                            <div
                              key={index}
                              className={`installation-planning__top ${
                                moreThanOne
                                  ? "installation-planning__top--more"
                                  : ""
                              }`}
                            >
                              <div className="installation-planning__left">
                                <div className="installation-planning__label">
                                  <span>{counter}</span>
                                  {MEASUREMENTS_TYPE_TRANSLATIONS[type]}
                                </div>
                                <div className="installation-planning__numbers">
                                  <div className="installation-planning__number-item">
                                    <SVG type={SVG_TYPE.CLOCK} />{" "}
                                    {plannedAssemblyTime}h
                                  </div>
                                  <div className="installation-planning__number-item">
                                    <SVG type={SVG_TYPE.USER} />{" "}
                                    {numberOfAssemblyTeams}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                    {isScheduler && <div className="am-separator" />}
                  </div>
                )}
            </>
          )}
          {measurementsArrangedInstallations &&
            measurementsArrangedInstallations.length > 0 && (
              <>
                <div className="determined-and-arranged-installation">
                  <div className="modal__information-header determined-and-arranged-installation__header">
                    {isFitter ? "Montaż ustalony" : "Montaże ustalone"}
                    {editAccess && (
                      <Button
                        size="small"
                        label="Edytuj"
                        onClick={() => handleClickAppointment("edit")}
                      />
                    )}
                    {isActiveHandleClickInstallation && (
                      <Button
                        size="small"
                        label="Wykonaj"
                        onClick={() => handleClickInstallation()}
                      />
                    )}
                  </div>
                  <div className=" determined-and-arranged-installation__installation-list">
                    {measurementsArrangedInstallations
                      .filter(({ groupUuid }) => {
                        if (isFitter) {
                          return (
                            groupUuid === order.groupUuid ||
                            groupUuid === groupUuidUrl
                          );
                        }
                        return true;
                      })
                      .map(({ measurements, dates, schedulerComments }) => {
                        return (
                          <div className="installation-planning">
                            <div className="determined-and-arranged-installation__installation-item">
                              {measurements.map(({ type, counter }, index) => {
                                const moreThanOne = index > 0;
                                return (
                                  <div
                                    key={index}
                                    className={`installation-planning__top ${
                                      moreThanOne
                                        ? "installation-planning__top--more"
                                        : ""
                                    }`}
                                  >
                                    <div className="installation-planning__left">
                                      <div className="installation-planning__label">
                                        <span>{counter}</span>

                                        {MEASUREMENTS_TYPE_TRANSLATIONS[type]}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            {dates.map(({ date, teams }, index) => {
                              const isMoreThanOne = index > 0;
                              return (
                                <>
                                  <div className="determined-and-arranged-installation__date">
                                    {isMoreThanOne && (
                                      <div className="am-separator" />
                                    )}
                                    <div className="determined-and-arranged-installation__date-content">
                                      <p>Termin</p>
                                      <span>{date}</span>
                                    </div>
                                  </div>
                                  {teams.map(({ start, end, employees }) => (
                                    <div className="determined-and-arranged-installation__teams">
                                      <div className="determined-and-arranged-installation__teams__item">
                                        <div className="determined-and-arranged-installation__teams__item-label">
                                          Przypisani pracownicy
                                        </div>
                                        <div className="determined-and-arranged-installation__teams__item-workers">
                                          {employees.map(
                                            (
                                              { firstName, lastName },
                                              index
                                            ) => {
                                              const isMoreThanOneAndNotLast =
                                                index + 1 < employees.length;
                                              return (
                                                <div className="determined-and-arranged-installation__teams__item-employee">
                                                  {firstName} {lastName}
                                                  {isMoreThanOneAndNotLast
                                                    ? ", "
                                                    : ""}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                      <div className="determined-and-arranged-installation__teams__item determined-and-arranged-installation__teams__item--hours">
                                        <div className="determined-and-arranged-installation__teams__item-label">
                                          Przedział godzinowy
                                        </div>
                                        <div className="determined-and-arranged-installation__teams__item-hours">
                                          {start} - {end}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  {schedulerComments && (
                                    <>
                                      <div className="am-separator determined-and-arranged-installation__comment-separator" />
                                      <div className="determined-and-arranged-installation__date-content">
                                        <p>Komentarz</p>
                                      </div>
                                      <div className="determined-and-arranged-installation__comment">
                                        {schedulerComments}
                                      </div>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                </div>
                {isScheduler && <div className="am-separator" />}
              </>
            )}
        </>
      )}
    </>
  );
};

export default DeterminedAndArrangedInstallation;
