import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type InstallationInfoData = {
  measurementUuid: string;
  constructionSiteUuids: Array<{ uuid: string, comment?: string }>;
  theBuildingIsMadeOf: 'ceramicBlock' | 'yTong' | 'silicate' | 'woodenStructure' | 'other';
  theBuildingIsMadeOfComment?: string;
  plannedThickness: string;
  installationElementsUuids: Array<{ uuid: string, comment?: string }>;
  numberOfAssemblyTeams: string;
  plannedAssemblyTime: string;
};

const usePutInstallationInfo = (
  options?: UseMutationOptions<any, Error, InstallationInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editInstallationInfo'],
    mutationFn: async (data) => {
      return axiosInstance.put(`/measurement/installation-info`, data).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych montażowych...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-installation-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane montażowe zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-installation-info',
      });
      queryClient.invalidateQueries({ queryKey: ['orderDetails'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych montażowych.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-installation-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutInstallationInfo;
