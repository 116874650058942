/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import "./building-material-component.scss";
import { StaticAlert, CheckboxPart } from "components";

interface FloorLayingPatternType {
  field: any;
  errors?: any;
  form: any;
}

const FloorLayingPattern = ({
  field,
  form,
  errors,
}: FloorLayingPatternType) => {
  const [floorLayingPattern, setFloorLayingPattern] = useState(
    form.values.flooLayingPattern || ""
  );

  useEffect(() => {
    setFloorLayingPattern(form.values.flooLayingPattern);
  }, [form.values[field.name]]);

  const BuildingMaterialCheckbox = [
    {
      label: "Na trzy",
      value: "onThree",
      uuid: "onThree",
    },
    {
      label: "Mieszany",
      value: "mixed",
      uuid: "mixed",
    },
    {
      label: "Symetryczny",
      value: "symmetrical",
      uuid: "symmetrical",
    },
    {
      label: "Jodełka",
      value: "herringbone",
      uuid: "herringbone",
    },
  ];

  const handleCheckMaterial = (item: any) => {
    if (floorLayingPattern === item.value) {
      setFloorLayingPattern("");
    } else {
      setFloorLayingPattern(item.value);
    }
  };

  useEffect(() => {
    form.setFieldValue(field.name, floorLayingPattern);
  }, [floorLayingPattern]);

  return (
    <div className="building-material-component">
      <div className="am-section-heading">
        Materiał, z którego został wykonany budynek
      </div>
      <div className="am-section-checkbox-list construction-site__list">
        {BuildingMaterialCheckbox.map((item: any) => {
          return (
            <CheckboxPart
              className="radiobox"
              onChange={() => handleCheckMaterial(item)}
              checked={floorLayingPattern === item.value}
              key={item.value}
              label={item.label}
              uuid={item.value}
            />
          );
        })}
      </div>
      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={errors[field.name] && errors[field.name]}
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}

      <div className="am-separator" />
    </div>
  );
};

export default FloorLayingPattern;
