import { Button, FileItem, SkeletonRow, StaticAlert } from "components";
import { useRef, useState } from "react";
import { useUploadFile } from "hooks/utils";
import { OrderType } from "types/OrderTypes";
import { FILE_ENUM, ORDER_ENUM, USER_ROLE } from "enums";
import "./order-invoice.scss";

interface OrderInvoiceProps {
  orderDetails: OrderType;
  userRole: string | undefined;
}

const OrderInvoice = ({ orderDetails, userRole }: OrderInvoiceProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { fileInvoice, status } = orderDetails;
  const {
    INSTALLATION_IN_PROGESS,
    FIXED_INSTALLATION,
    INSTALLATION_TO_BE_RELEASED,
  } = ORDER_ENUM;
  const isFileInvoice = fileInvoice?.url;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isInvoiceError, setIsInvoiceError] = useState<boolean>(false);
  const uploadFile = useUploadFile({
    onSuccess: () => {
      setSelectedFile(null);
    },
  });

  const isScheduler = userRole === USER_ROLE.scheduleManager;
  const isAdmin = userRole === USER_ROLE.admin;
  const isActiveStatusForScheduler =
    status === INSTALLATION_TO_BE_RELEASED ||
    status === FIXED_INSTALLATION ||
    status === INSTALLATION_IN_PROGESS;
  const isActiveForScheduler = isScheduler && isActiveStatusForScheduler;
  const isActiveForAdmin = isAdmin && isFileInvoice;
  const isAvailable = isActiveForAdmin || isActiveForScheduler;

  const handleAddInvoice = () => {
    fileInputRef.current?.click();
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const MAX_FILE_SIZE_MB = 10;
    const files = event.target.files ? Array.from(event.target.files) : [];
    const pdfFile = files.find((file) => file.type === "application/pdf");

    if (pdfFile) {
      const fileSizeMB = pdfFile.size / (1024 * 1024);

      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        setIsInvoiceError(true);
        return;
      }

      setIsInvoiceError(false);
      setSelectedFile(pdfFile);

      uploadFile.mutate({
        parentUuid: orderDetails?.uuid || "",
        type: FILE_ENUM.ORDER_INVOICE,
        files: [pdfFile],
      });

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      setIsInvoiceError(true);
    }
  };

  return (
    <>
      {isAvailable && (
        <>
          <div className="am-separator" />
          <StaticAlert
            className="order-invoice__alert"
            show={isInvoiceError}
            label="Maksymalny rozmiar pliku 10MB"
          />
          <div className="modal__information-header">
            Faktura
            {isScheduler && !isFileInvoice && (
              <Button
                size="small"
                label={!selectedFile ? "Dodaj" : "Wgrywanie"}
                disabled={!!selectedFile}
                onClick={handleAddInvoice}
              />
            )}
          </div>
          {selectedFile && (
            <SkeletonRow className="order-invoice__skeleton" count={1} />
          )}
          {isFileInvoice && !selectedFile && (
            <div className="order-preview__information-file">
              <FileItem
                confirmation={{
                  isConfirmated: true,
                  text: "Czy jesteś pewien, że chcesz usunąć fakturę z tego zlecenia?",
                }}
                file={fileInvoice}
                isDeleteAvailable={isScheduler}
              />
            </div>
          )}
        </>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="application/pdf"
        onChange={handleFileSelect}
      />
    </>
  );
};

export default OrderInvoice;
