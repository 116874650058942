import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

interface ApproveInstallationProtocolVariables {
  groupUuid: string; 
  signatureContractor: File;
  signatureCustomer: File;
}

const useApproveInstallationProtocol = (
  options?: UseMutationOptions<any, Error, ApproveInstallationProtocolVariables>
) => {
  return useMutation({
    ...options,
    mutationKey: ['approveInstallationProtocol'],
    mutationFn: async ({ groupUuid, signatureContractor, signatureCustomer }) => {
      const formData = new FormData();
      formData.append('signatureContractor', signatureContractor);
      formData.append('signatureCustomer', signatureCustomer);

      return axiosInstance.post(
        `/installation/protocol-approve`,
        formData,
        {
          params: { groupUuid },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      ).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Zatwierdzanie protokołu odbioru...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'approve-installation-protocol',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Protokół odbioru został zatwierdzony!',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'approve-installation-protocol',
      });
      queryClient.invalidateQueries({ queryKey: ['orderDetails'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas zatwierdzania protokołu odbioru',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'approve-installation-protocol',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useApproveInstallationProtocol;
