/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { SelectOption } from "types/SelectTypes";
import Select, { components, ActionMeta } from "react-select";
import { SVG } from "components";
import { SVG_TYPE } from "enums";
import classNames from "classnames";
import { isMobile } from "utils";

interface SelectProps {
  label?: string;
  options?: SelectOption[];
  placeholder: string;
  name: string;
  filterOption?: any;
  checker?: boolean;
  white?: boolean;
  maxLength?: number;
  optional?: boolean;
  disableMobileKeyboard?: boolean;
  menuPlacement?: "top" | "bottom";
  field?: any;
  errors?: any;
  form?: any;
  touched?: any;
  onChange?: (data: any) => void;
  handleOnChange?: any;
  isOnBlurAction?: boolean;
  editUser?: () => void;
  onFocus?: any;
  disabled?: boolean;
  size?: "large" | "small" | "medium";
  className?: string;
  loadOptions?: (inputValue: string) => Promise<SelectOption[]>;
  onCloseChoice?: () => void;
}

const SelectWithFetch = ({
  label,
  options,
  name,
  filterOption,
  placeholder,
  field,
  form,
  errors,
  onChange,
  disabled = false,
  checker = false,
  optional = false,
  disableMobileKeyboard = false,
  white = false,
  size = "large",
  menuPlacement = "bottom",
  className = "",
  maxLength,
  loadOptions,
  onCloseChoice,
}: SelectProps) => {
  // const [initialOptions, setInitialOptions] = useState<any>();
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null
  );
  // console.log(initialOptions);
  const [inputValue, setInputValue] = useState("");
  const [fetchedOptions, setFetchedOptions] = useState<SelectOption[]>(
    options || []
  );
  const CustomDropdownIndicator = (props: any) => {
    const { selectProps } = props;
    return (
      <components.DropdownIndicator {...props}>
        {!selectProps.value ? (
          <SVG type={SVG_TYPE.SEARCH} />
        ) : (
          <>
            <div
              className="close"
              onClick={() => {
                setSelectedOption(null);
                setFetchedOptions([]);
                onCloseChoice && onCloseChoice();
              }}
            >
              <SVG type={SVG_TYPE.CLOSE} />
            </div>
          </>
        )}
      </components.DropdownIndicator>
    );
  };
  const selectClass = classNames("select", className, {
    "select--white": white,
    "select--disabled": disabled,
    "select--large": size === "large",
    "select--medium": size === "medium",
    "select--small": size === "small",
    "select--optional": optional,
  });

  const selectPrefixChecker = classNames({
    "select-checker": checker,
    "select-checker--large": checker && size === "large",
    "select-checker--medium": checker && size === "medium",
    "select-checker--small": checker && size === "small",
    select: !checker,
  });

  const selectPrefixClass = classNames("multiple-select", {
    "multiple-select--large": size === "large",
    "multiple-select--medium": size === "medium",
    "multiple-select--small": size === "small",
  });

  const handleChange = (
    newValue: SelectOption | null,
    actionMeta?: ActionMeta<SelectOption>
  ) => {
    setSelectedOption(newValue);
    const valueToStore = newValue ? newValue.value : "";
    form.setFieldValue(field.name, valueToStore);
    onChange && onChange(newValue);
  };

  const NoOptionsMessage = () => {
    return <div className="empty" />;
  };

  const handleInputChange = async (newValue: string) => {
    setInputValue(newValue);
    if (maxLength && newValue.length > maxLength) {
      setInputValue(newValue.slice(0, maxLength));
    } else {
      setInputValue(newValue);
    }

    if (loadOptions && newValue.length >= 3) {
      try {
        const loadedOptions = await loadOptions(newValue);
        setFetchedOptions(loadedOptions);
      } catch (error) {
        console.error("Error loading options: ", error);
        setFetchedOptions([]);
      }
    } else {
      setFetchedOptions([]);
    }
  };

  const filterOptions = (candidate: SelectOption, input: string) => {
    if (filterOption) {
      return filterOption(candidate, input);
    }
    return candidate.label.toLowerCase().includes(input.toLowerCase());
  };

  const limitedOptions = inputValue
    ? fetchedOptions
        ?.filter((option) => filterOptions(option, inputValue))
        .slice(0, maxLength)
    : fetchedOptions;

  useEffect(() => {
    if (form.values[field.name] === "") {
      setSelectedOption(null);
    }
  }, [form.values[field.name]]);

  useEffect(() => {
    if (field.value) {
      const initialValues = { value: field.value, label: field.value };
      setSelectedOption(initialValues);
      // setInitialOptions([initialValues]);
    }
  }, []);

  return (
    <div className="select__wrapper">
      <div className="select__label">
        {label}{" "}
        {optional ? <span className="select__optional">(opcjonalne)</span> : ""}
      </div>
      <Select
        value={selectedOption}
        name={name}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        isClearable={optional}
        onChange={handleChange}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        placeholder={placeholder ? placeholder : ""}
        options={limitedOptions}
        className={selectClass}
        classNamePrefix={checker ? selectPrefixChecker : selectPrefixClass}
        noOptionsMessage={() => <NoOptionsMessage />}
        filterOption={filterOption}
        isSearchable={disableMobileKeyboard && isMobile() ? false : true}
        menuPlacement={menuPlacement}
      />
      {errors && form.touched && (
        <div className="select__error">
          {form.touched[field.name] && <span>{errors[field.name]}</span>}
        </div>
      )}
    </div>
  );
};

export default SelectWithFetch;
