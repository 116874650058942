/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./tri-state.scss";

interface TriStateType {
  form?: any;
  name?: any;
  first?: { name: string; value: string };
  second?: { name: string; value: string };
  third?: { name: string; value: string };
  value?: boolean | null;
  secondary?: boolean;
  label?: string;
  className?: string;
  field?: any;
  onChange?: (event: any) => void;
}

const TriState = ({
  form,
  name,
  first,
  second,
  third,
  secondary = false,
  label,
  className = "",
  field,
  onChange,
}: TriStateType) => {
  const [currentValue, setCurrentValue] = useState<any>(form.values[name]);

  useEffect(() => {
    field &&
      field?.name &&
      form.values[field?.name] &&
      setCurrentValue(form.values[field?.name]);
  }, [form.values[field?.name] && form.values[field?.name]]);
  useEffect(() => {
    setCurrentValue(form.values[name || field.name]);
  }, [name && form.values[name]]);

  useEffect(() => {
    form.setFieldValue(field?.name || name, currentValue);
  }, [currentValue]);

  const handleChange = (newValue: any) => {
    setCurrentValue(newValue);
    form.setFieldValue(field?.name || name, newValue);
    if (onChange) {
      onChange({ target: { name, value: newValue } });
    }
  };

  return (
    <div className={`tristate__wrapper ${className}`}>
      {label && <div className="tristate__name">{label}</div>}
      <div className={`tristate ${secondary ? "tristate__secondary" : ""}`}>
        <button
          type="button"
          className={`tristate__option ${
            currentValue === first?.value ? "active" : ""
          }`}
          onClick={() => handleChange(first?.value)}
        >
          {first?.name}
        </button>
        <button
          type="button"
          className={`tristate__option ${
            currentValue === second?.value ? "active" : ""
          }`}
          onClick={() => handleChange(second?.value)}
        >
          {second?.name}
        </button>
        <button
          type="button"
          className={`tristate__option ${
            currentValue === third?.value ? "active" : ""
          }`}
          onClick={() => handleChange(third?.value)}
        >
          {third?.name}
        </button>
      </div>
    </div>
  );
};

export default TriState;
