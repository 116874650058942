import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type AddItemData = {
  groupUuid: string;
  name: string;
};

const useAddItemLeftAtClient = (
  options?: UseMutationOptions<any, Error, AddItemData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['addItem'],
    mutationFn: async (data: AddItemData) => {
      return axiosInstance
        .post(`/installation/items/create`, data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Dodawanie rzeczy...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'add-item',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Rzecz została dodana pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'add-item',
      });
      queryClient.invalidateQueries({ queryKey: ['orderDetails'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas dodawania rzeczy.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'add-item',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useAddItemLeftAtClient;
