import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';

interface MeasurementList {
  uuid: string;
  typeOfMeasurement: string;
  isFinished: boolean;
  measurementLabel: string;
  roomName: string;
}
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default function useGetMeasurements(orderUuid: string | null, options = {}) {
  return useQuery({
    queryKey: ['measurements', orderUuid],
    queryFn: async () => {
      await delay(500); 
      return axiosInstance
        .get(`/measurement/list?orderUuid=${orderUuid}`)
        .then((res) => res.data);
    },
    select: (data: {measurements: MeasurementList[]}) => data.measurements,
    retry: false,
    enabled: !!orderUuid,
    ...options,
  });
}