import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

interface ToggleCheckListStatusType {
  groupUuid: string;
  checkListUuid: string;
  status: boolean;
}

const useToggleCheckListStatus = (
  options?: UseMutationOptions<any, Error, ToggleCheckListStatusType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['toggleCheckListStatus'],
    mutationFn: async ({ groupUuid, checkListUuid, status }: ToggleCheckListStatusType) => {
      return axiosInstance.put(`/check-list/${checkListUuid}/status`, {
        groupUuid,
        checkListUuid,
        status,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie aktualizacji statusu...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'toggle-checklist-status',
      });
      if (options?.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Status checklisty został zaktualizowany pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'toggle-checklist-status',
      });
      queryClient.invalidateQueries({ queryKey: ['checklistOrder'] });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji statusu checklisty.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'toggle-checklist-status',
      });
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useToggleCheckListStatus;
