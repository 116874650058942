import { Field, Formik } from "formik";
import { Button, Modal, Input } from "components";
import { VALIDATION_MESSAGES } from "enums";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom";
import { useAddItemLeftAtClient } from "hooks/orders";

interface AddingItemsLeftAtClientProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddingItemsLeftAtClient = ({
  isOpen,
  onClose,
}: AddingItemsLeftAtClientProps) => {
  const [searchParams] = useSearchParams();
  const groupUuid = searchParams.get("groupUuid");
  const { mutate: addingItem } = useAddItemLeftAtClient();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = async (values: any, actions: any) => {
    await addingItem(values);
    actions.resetForm();
  };

  return (
    <Modal side="right" isOpened={isOpen} onClose={() => onClose()}>
      <>
        <div className="modal-heading">
          Dodawanie rzeczy pozostawionych u klienta
        </div>
        <Formik
          initialValues={{ name: "", groupUuid }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values, actions) => handleOnSubmit(values, actions)}
        >
          {({ handleSubmit, errors }) => {
            return (
              <form className="add-user__form form" onSubmit={handleSubmit}>
                <Field
                  errors={errors}
                  label="Nazwa"
                  name="name"
                  component={Input}
                  maxLength={60}
                />
                <div className="modal-buttons">
                  <Button stroke onClick={() => onClose()} label="Anuluj" />
                  <Button onClick={() => handleSubmit()} label="Potwierdź" />
                </div>
              </form>
            );
          }}
        </Formik>
      </>
    </Modal>
  );
};

export default AddingItemsLeftAtClient;
