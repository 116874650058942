import { Button, Input, Select, SVG, StaticAlert } from "components";
import { FieldArray, Field } from "formik";
import "./window-sills.scss";
import { SVG_TYPE } from "enums";

interface WindowSillsType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
}

const WindowSills = ({ errors, form }: WindowSillsType) => {
  return (
    <div className="hole-dimension window-sills">
      <div>
        <FieldArray
          name="windowSill"
          render={(arrayHelpers) => (
            <div>
              <div className="am-section-heading window-sills__heading">
                <h3>
                  Parapety <span>(opcjonalne)</span>
                </h3>
                {form.values?.windowSill.length === 0 && (
                  <Button
                    size="small"
                    stroke
                    label="Dodaj parapet"
                    onClick={() => {
                      arrayHelpers.push({
                        type: "",
                        width: "",
                        length: "",
                        thickness: "",
                        material: "",
                      });
                    }}
                  />
                )}
              </div>
              {form.values?.windowSill.map((_: any, index: any) => (
                <div key={index} className="window-sills__item">
                  <div className="window-sills__row">
                    <Field
                      label="Rodzaj"
                      name={`windowSill.${index}.type`}
                      placeholder="Wybierz"
                      component={Select}
                      errors={errors}
                      options={[
                        { value: "interior", label: "Wewnętrzny" },
                        { value: "external", label: "Zewnętrzny" },
                      ]}
                      white
                    />
                    <div
                      className="window-sills__delete"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <SVG type={SVG_TYPE.BIN} />
                      Usuń ten parapet
                    </div>
                  </div>
                  <Field
                    errors={errors}
                    label="Szerokość"
                    type="number"
                    name={`windowSill.${index}.width`}
                    component={Input}
                    rightPlaceholder="mm"
                  />
                  <Field
                    errors={errors}
                    label="Długość"
                    type="number"
                    name={`windowSill.${index}.length`}
                    component={Input}
                    rightPlaceholder="mm"
                  />
                  <Field
                    errors={errors}
                    label="Grubość"
                    type="number"
                    name={`windowSill.${index}.thickness`}
                    component={Input}
                    rightPlaceholder="mm"
                    optional
                  />
                  <Field
                    errors={errors}
                    label="Materiał"
                    name={`windowSill.${index}.material`}
                    component={Input}
                    optional
                  />
                  {form.values?.windowSill?.length > index + 1 && (
                    <div className="am-separator" />
                  )}
                </div>
              ))}
              {form.values?.windowSill.length > 0 && (
                <Button
                  stroke
                  label="Dodaj parapet"
                  size="medium"
                  className="window-sills__add-button"
                  onClick={() => {
                    arrayHelpers.push({
                      type: "",
                      width: "",
                      length: "",
                      thickness: "",
                      material: "",
                    });
                  }}
                />
              )}
              {errors && form.touched && (
                <>
                  <StaticAlert
                    className="am-alert"
                    size="small"
                    show={errors["windowSill"] && errors["windowSill"]}
                    label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
                  />
                </>
              )}
              <div className="am-separator" />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default WindowSills;
