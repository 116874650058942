import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';
import {PaymentMethodEnum} from 'types/OrderTypes';

type InvoiceInfoData = {
  uuid: string;
  invoiceByMail?: boolean;
  mailAddress?: string;
  paymentMethod?: PaymentMethodEnum;
};

const usePutInvoiceInfo = (
  options?: UseMutationOptions<any, Error, InvoiceInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editInvoiceInfo'],
    mutationFn: async (data: InvoiceInfoData) => {
      return axiosInstance
        .put(`/orders/invoice-info/${data.uuid}`, data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych o fakturze...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-invoice-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane o fakturze zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-invoice-info',
      });
      queryClient.invalidateQueries({ queryKey: ['invoiceInfo', variables.uuid] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych o fakturze.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-invoice-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutInvoiceInfo;