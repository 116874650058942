import { useState } from "react";
import { Field } from "formik";
import { Textarea } from "components";
import "./follow-up-meeting.scss";

const FollowUpMeeting = () => {
  const [isFollowUpRequired, setIsFollowUpRequired] = useState(false);

  const handleClickCheckbox = () => {
    setIsFollowUpRequired(!isFollowUpRequired);
  };

  const handleUpdateClientComment = (comment: string) => {
    console.log(comment);
  };

  return (
    <>
      <div className="receipt-protocol__client receipt-protocol__client--comment">
        <input
          name="client"
          id="client"
          type="checkbox"
          className="receipt-protocol__checkbox"
          defaultChecked={isFollowUpRequired}
          onClick={handleClickCheckbox}
        />
        <label htmlFor="client">
          Konieczność umówienia spotkania uzupełniającego
        </label>
        {isFollowUpRequired && (
          <Field
            className="receipt-protocol-generator__textarea"
            label="Komentarz"
            optional
            name="comments"
            placeholder=""
            component={Textarea}
            onBlur={(e: any) => handleUpdateClientComment(e.target.value)}
          />
        )}
      </div>
    </>
  );
};

export default FollowUpMeeting;
