import { Input, Select, Textarea } from "components";
import { Field } from "formik";
import {
  SingleSectionElement,
  HoleDimension,
  AdditionalWarning,
} from "features/addingMeasurement";

interface TiltingGateFormType {
  touched: any;
  errors: any;
  validateField: any;
  values: any;
  isErrorDimension: boolean;
  setFieldValue: any;
  setFieldError: any;
}

const TiltingGateForm = ({
  touched,
  errors,
  validateField,
  values,
  isErrorDimension,
  setFieldValue,
  setFieldError,
}: TiltingGateFormType) => {
  return (
    <>
      <Field
        errors={errors}
        validateField={validateField}
        label="Wymiar otworu"
        component={HoleDimension}
      />
      <SingleSectionElement
        className="padding-top-separator single-section-element--strips gateSize"
        touched={touched}
        name={["gateSizeWidth", "gateSizeHeight"]}
        errors={errors}
      >
        <div className="am-section-heading">Wymiar bramy</div>
        <Field
          label="Szerokość"
          name="gateSizeWidth"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
        <Field
          label="Wysokość"
          name="gateSizeHeight"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
        <AdditionalWarning
          isErrorDimension={isErrorDimension}
          errors={errors}
          values={values}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name={["methodOfOpeningManualAutomatic", "typeOfRail"]}
        errors={errors}
        className="padding-top-separator tilting-gate-selects"
      >
        <Field
          label="Sposób otwierania"
          name="methodOfOpeningManualAutomatic"
          type="number"
          options={[
            {
              value: "manual",
              label: "Ręczna",
            },
            {
              value: "automatic",
              label: "Automatyczna",
            },
          ]}
          component={Select}
          white
        />
        {values.methodOfOpeningManualAutomatic === "automatic" && (
          <Field
            label="Rodzaj szyny"
            name="typeOfRail"
            type="number"
            options={[
              {
                value: "chain",
                label: "Łańcuch",
              },
              {
                value: "strap",
                label: "Pasek",
              },
            ]}
            component={Select}
            white
          />
        )}
      </SingleSectionElement>

      <SingleSectionElement
        touched={touched}
        name="gateType"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          label="Typ bramy"
          name="gateType"
          type="number"
          options={[
            {
              value: "insulated",
              label: "Ocieplona",
            },
            {
              value: "uninsulated",
              label: "Nieocieplona",
            },
          ]}
          component={Select}
          white
        />
      </SingleSectionElement>

      <SingleSectionElement
        touched={touched}
        name="color"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label={`Kolor`}
          name="color"
          optional
          component={Input}
        />
      </SingleSectionElement>
      <Field
        className="am-margin-top"
        errors={errors}
        label="Dodatkowe uwagi"
        optional
        name="comments"
        component={Textarea}
        maxLength={250}
      />
    </>
  );
};

export default TiltingGateForm;
