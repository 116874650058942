import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { queryClient } from 'App';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { useSearchParams } from "react-router-dom";

interface DeleteFileData {
  uuid: string; 
  type: string;
}

const useDeleteFile = (
  options?: UseMutationOptions<any, Error, DeleteFileData>
) => {
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  return useMutation({
    ...options,
    mutationKey: ['deleteFile'],
    mutationFn: async ({ uuid }: DeleteFileData) => {
      return axiosInstance.delete(`/files/${uuid}`).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Trwa usuwanie pliku.',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'delete-file',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['orderDetails', orderUuid] });
      Toast({
        message: 'Plik został usunięty',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'delete-file',
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Plik nie został usunięty',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'delete-file',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useDeleteFile;
