import { useState } from "react";
import { Button, SVG } from "components";
import { SVG_TYPE } from "enums";
import "./items-left-at-client.scss";
import {
  AddingItemsLeftAtClient,
  RemoveItemLeftAtClient,
} from "features/orders";
import { OrderType } from "types/OrderTypes";
import { useSearchParams } from "react-router-dom";

interface ItemsLeftAtClientProps {
  orderDetails: OrderType;
  isProtocolApproved: boolean;
}

const ItemsLeftAtClient = ({
  orderDetails,
  isProtocolApproved,
}: ItemsLeftAtClientProps) => {
  const [searchParams] = useSearchParams();
  const groupUuid = searchParams.get("groupUuid");
  const { measurementsArrangedInstallations } = orderDetails;
  const [modalManage, setModalManage] = useState({
    which: "",
    element: "",
    uuid: "",
  });
  const itemsLeft = measurementsArrangedInstallations?.find(
    (installationGroup) => installationGroup.groupUuid === groupUuid
  )?.itemsLeft;

  const openAddingModal = () => {
    setModalManage({ which: "add", element: "", uuid: "" });
  };
  const openDeletingModal = (name: string, uuid: string) => {
    setModalManage({ which: "delete", element: name, uuid });
  };
  const closeModal = () => {
    setModalManage({ which: "", element: "", uuid: "" });
  };
  const isOpenAddingModal = modalManage.which === "add";

  return (
    <>
      <AddingItemsLeftAtClient
        isOpen={isOpenAddingModal}
        onClose={closeModal}
      />
      <RemoveItemLeftAtClient modalManage={modalManage} onClose={closeModal} />
      <div className={isProtocolApproved ? "checklist-approved" : ""}>
        <div className="active-order__bar items-left-at-client--active-order">
          <div className="">
            <div className="active-order__heading items-left-at-client__heading">
              <p>
                Rzeczy pozostawione u klienta
                <span>(opcjonalne)</span>
              </p>
              {!isProtocolApproved && (
                <Button
                  className="items-left-at-client__button"
                  stroke
                  svgType={SVG_TYPE.PLUS}
                  size="medium"
                  label={`Dodaj `}
                  onClick={() => openAddingModal()}
                />
              )}
            </div>
          </div>
        </div>
        {itemsLeft && itemsLeft.length > 0 && (
          <div className="items-left-at-client__list">
            {itemsLeft.map(({ name, uuid }) => {
              return (
                <div className="items-left-at-client__item">
                  <p className="items-left-at-client__item--label">{name}</p>
                  {!isProtocolApproved && (
                    <div
                      className="items-left-at-client__item-delete"
                      onClick={() => openDeletingModal(name, uuid)}
                    >
                      <SVG
                        className="items-left-at-client__item--icon"
                        type={SVG_TYPE.BIN}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default ItemsLeftAtClient;
