/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { SVG } from "components";
import { SVG_TYPE } from "enums";

interface SignatureProps {
  type: "client" | "employee";
  onCanvasStateChange: (isEmpty: boolean) => void;
  setValidation: (isEmpty: boolean) => void;
  clearCanvasTrigger?: () => void;
  isActiveClear?: boolean;
  isValid?: boolean;
  wasClientPresent?: boolean;
  onExport: (file: File | null) => void;
}

const Signature = ({
  type,
  onCanvasStateChange,
  clearCanvasTrigger,
  isActiveClear,
  isValid,
  wasClientPresent,
  setValidation,
  onExport,
}: SignatureProps) => {
  const { editor, onReady } = useFabricJSEditor();
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const isClient = type === "client";

  useEffect(() => {
    if (editor && editor.canvas) {
      editor.canvas.isDrawingMode = true;
      editor.canvas.freeDrawingBrush = new fabric.PencilBrush(editor.canvas);
      editor.canvas.freeDrawingBrush.color = "black";
      editor.canvas.freeDrawingBrush.width = 2;

      const checkCanvasEmpty = () => {
        const isEmpty = editor.canvas.getObjects().length === 0;
        setIsCanvasEmpty(isEmpty);
        onCanvasStateChange(isEmpty);
        setValidation(isEmpty);
        if (!isEmpty) {
          exportToJPEG();
        } else {
          onExport(null);
        }
      };

      editor.canvas.on("object:modified", checkCanvasEmpty);
      editor.canvas.on("object:added", checkCanvasEmpty);
      editor.canvas.on("object:removed", checkCanvasEmpty);
      editor.canvas.on("path:created", checkCanvasEmpty);

      return () => {
        editor.canvas.off("object:modified", checkCanvasEmpty);
        editor.canvas.off("object:added", checkCanvasEmpty);
        editor.canvas.off("object:removed", checkCanvasEmpty);
        editor.canvas.off("path:created", checkCanvasEmpty);
      };
    }
  }, [editor]);

  const exportToJPEG = () => {
    if (editor && editor.canvas) {
      const tempCanvas = document.createElement("canvas");
      const originalCanvas = editor.canvas.getElement();

      tempCanvas.width = originalCanvas.width;
      tempCanvas.height = originalCanvas.height;

      const tempContext = tempCanvas.getContext("2d");
      tempContext?.drawImage(originalCanvas, 0, 0);

      const dataURL = tempCanvas.toDataURL("image/jpeg");
      fetch(dataURL)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `${type}-signature.jpeg`, {
            type: "image/jpeg",
          });
          onExport(file);
        });
    }
  };

  const onClearCanvas = () => {
    if (editor && editor.canvas) {
      editor.canvas.clear();
      setIsCanvasEmpty(true);
      onCanvasStateChange(true);
      clearCanvasTrigger && clearCanvasTrigger();
    }
  };

  useEffect(() => {
    if (clearCanvasTrigger && isActiveClear) {
      onClearCanvas();
    }
  }, [clearCanvasTrigger]);

  return (
    <div
      className={`receipt-protocol__box ${
        wasClientPresent ? "receipt-protocol__box--disabled" : ""
      } ${isValid ? "receipt-protocol__box--error" : ""}`}
    >
      <div className="receipt-protocol__box__heading receipt-protocol__signature__heading">
        <span>Podpis {isClient ? "zamawiającego" : "wykonawcy"}</span>
        {!isCanvasEmpty && (
          <div
            className="receipt-protocol__signature__icon"
            onClick={onClearCanvas}
          >
            <SVG type={SVG_TYPE.TRASH} />
          </div>
        )}
      </div>

      <div className="receipt-protocol__box__signature">
        <FabricJSCanvas className="canvas" onReady={onReady} />
      </div>

      <div className="receipt-protocol__box__footer">
        {isClient ? "Czytelny podpis" : ""}
      </div>
    </div>
  );
};

export default Signature;
