import { OrderType } from "types/OrderTypes";
import "./completed-measurements.scss";
import { USER_ROLE, ORDER_ENUM } from "enums";
import { hasAccess, hasOrderStatus } from "utils";
import { useGetMeasurements } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { SkeletonRow } from "components";
import { FileItem } from "components";

interface CompletedMeasurementsProps {
  orderDetails: OrderType;
  userRole: string | undefined;
}

const CompletedMeasurements = ({
  orderDetails,
  userRole,
}: CompletedMeasurementsProps) => {
  const { scheduleManager, admin, fitter, measurer } = USER_ROLE;
  const {
    VERIFICATION_TO_BE_RELEASED,
    FIXED_VERIFICATION,
    INSTALLATION_TO_BE_RELEASED,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_DONE,
  } = ORDER_ENUM;
  const { status } = orderDetails;
  const [searchParams] = useSearchParams();
  const orderUuid =
    searchParams.get("orderUuid") || searchParams.get("uuid") || "";
  const isScheduleManager = hasAccess(userRole, [scheduleManager]);
  const { data: measurements, isLoading } = useGetMeasurements(orderUuid);
  const orderStatusAccess = hasOrderStatus(status, [
    VERIFICATION_TO_BE_RELEASED,
    FIXED_VERIFICATION,
    INSTALLATION_TO_BE_RELEASED,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_DONE,
  ]);
  const roleAccess = hasAccess(userRole, [
    admin,
    fitter,
    scheduleManager,
    measurer,
  ]);

  const showSection = roleAccess && orderStatusAccess;

  return (
    <>
      {showSection && (
        <div className="completed-measurements">
          {isScheduleManager && <div className="am-separator" />}
          <div className="modal__information-header determined-and-arranged-installation__header">
            Wykonane pomiary
          </div>
          {!isLoading ? (
            <>
              {measurements?.map(({ measurementLabel, roomName }) => {
                const fileItem = {
                  name: `${measurementLabel}${
                    roomName ? ` - ${roomName}` : ""
                  }`,
                  createdAt: "string",
                  id: 1,
                  modelName: "string",
                  parentId: 1,
                  subDir: "string",
                  type: "string",
                  updatedAt: "string",
                  url: "string",
                  uuid: "string",
                };
                return <FileItem file={fileItem} />;
              })}
            </>
          ) : (
            <SkeletonRow count={3} />
          )}
        </div>
      )}
    </>
  );
};

export default CompletedMeasurements;
