/* eslint-disable react-hooks/exhaustive-deps */
import { FloatingMenu } from "components";
import { useState, useEffect } from "react";
import { EmployeesTimeStats, OrdersTimeStats } from "features/statistics";
import { useSearchParams } from "react-router-dom";
import "./statistics.scss";

const subMenuItems = [
  {
    label: "Pracownicy",
    value: "employees",
  },
  {
    label: "Zlecenia",
    value: "orders",
  },
];

const Statistics = () => {
  const [activeFloatingMenuItem, setActiveFloatingMenuItem] = useState(
    subMenuItems[0]
  );
  const isEmployeesActive =
    activeFloatingMenuItem && activeFloatingMenuItem.value === "employees";
  const isOrdersActive =
    activeFloatingMenuItem && activeFloatingMenuItem.value === "orders";
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const handleSetTab = (item: any) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("order");
    newSearchParams.set("tab", item?.value);
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    const activeTab = subMenuItems.find((item) => item.value === tab);
    if (activeTab) {
      setActiveFloatingMenuItem(activeTab);
    }
  }, []);

  return (
    <>
      <div className="dashboard-tab-name-with-button dashboard-tab-name-with-button--only-mobile statistics">
        <div className="dashboard-tab-name">Dashboard</div>
      </div>
      <div className="floating-menu-with-button statistics__floating-menu">
        <FloatingMenu
          items={subMenuItems}
          activeFloatingMenuItem={activeFloatingMenuItem}
          setActiveFloatingMenuItem={setActiveFloatingMenuItem}
          onClick={(item: any) => handleSetTab(item)}
        />
      </div>
      <main className="statistics">
        {isEmployeesActive && <EmployeesTimeStats />}
        {isOrdersActive && <OrdersTimeStats />}
      </main>
    </>
  );
};

export default Statistics;
