import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';

type CustomerCommentData = {
  groupUuid: string;
  comment: string;
};

const usePutCustomerComment = (
  options?: UseMutationOptions<any, Error, CustomerCommentData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editCustomerComments'],
    mutationFn: async (data: CustomerCommentData) => {
      return axiosInstance
        .put(`/installation/customer-comments/${data.groupUuid}`, { comments: data.comment })
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie komentarza...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-customer-comments',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Komentarz zaktualizowany pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-customer-comments',
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji komentarza.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-customer-comments',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutCustomerComment;
