import { MEASUREMENTS_TYPE_TRANSLATIONS, SVG_TYPE } from "enums";
import { SVG } from "components";
import MEASUREMENTS_TYPE from "enums/measurementsTypes";
import "./installation-arranging.scss";
import moment from "moment";

interface InstallationArrangingTypes {
  installation: {
    measurements: Array<{
      type: MEASUREMENTS_TYPE;
      counter: number;
      numberOfAssemblyTeams: string;
      plannedAssemblyTime: string;
    }>;
    type: MEASUREMENTS_TYPE;
    dates: Array<{
      date: string;
      teams: Array<{
        end: string;
        start: string;
        employees: Array<{
          firstName: string;
          id: number;
          lastName: string;
          status: string;
          uuid: string;
        }>;
      }>;
    }>;
  };
}

const InstallationArranging = ({
  installation,
}: InstallationArrangingTypes) => {
  const { measurements, dates } = installation;
  const formatTime = (time: string) => {
    return moment(time, "HH:mm").format("H:mm");
  };
  return (
    <div className="installation-planning">
      {measurements.map(
        (
          { counter, type, numberOfAssemblyTeams, plannedAssemblyTime },
          index
        ) => {
          const moreThanOne = index > 0;
          return (
            <div
              key={index}
              className={`installation-planning__top ${
                moreThanOne ? "installation-planning__top--more" : ""
              }`}
            >
              <div className="installation-planning__left">
                <div className="installation-planning__label">
                  <span>{counter}</span>
                  {MEASUREMENTS_TYPE_TRANSLATIONS[type]}
                </div>
                <div className="installation-planning__numbers">
                  <div className="installation-planning__number-item">
                    <SVG type={SVG_TYPE.CLOCK} /> {plannedAssemblyTime}h
                  </div>
                  <div className="installation-planning__number-item">
                    <SVG type={SVG_TYPE.USER} /> {numberOfAssemblyTeams}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      )}

      <div className="installation-planning__bottom">
        {dates.map(({ date, teams }, index) => {
          return (
            <>
              <div
                key={index}
                className="installation-arranging__item installation-arranging__item-separator"
              >
                <div className="installation-arranging__label">Termin</div>
                <div className="installation-arranging__value">{date}</div>
              </div>
              {teams.map(({ employees, end, start }, index) => {
                return (
                  <>
                    <div
                      className={`installation-arranging__item installation-arranging__item--middle ${
                        teams.length === 1
                          ? "installation-arranging__item--only-one"
                          : ""
                      }`}
                    >
                      <div className="installation-arranging__label">
                        Pracownik/cy
                      </div>
                      <div className="installation-arranging__value">
                        {employees.map(({ firstName, lastName }) => {
                          return (
                            <div>
                              {firstName} {lastName}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="installation-arranging__item">
                      <div className="installation-arranging__label">
                        Przedział godzinowy
                      </div>
                      <div className="installation-arranging__value">
                        {formatTime(start)} - {formatTime(end)}
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default InstallationArranging;
